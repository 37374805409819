<template>
	<div class="container-fluid animatedParent animateOnce my-3">
		<!-- main content -->
		<div class="tab-content">
			<div class="tab-pane animated fadeInUpShort show active go">
				<div class="row my-3">
					<div class="col-md-12">
						<div class="card r-0 shadow">
							<div class="card-header">

								<div class="row">
									<div class="col-md-12">
										<!-- 搜索 start -->
										<div class="form-inline mt-3">
											
											<input v-model="query" class="form-control" placeholder="搜索" type="text">
											
											
											<!-- <div class="input-group">
												<input v-model="start_date" id="date_timepicker_start" type="text" class="date-time-picker form-control" style="width: 105px;">
												<span class="input-group-append">
													<span class="input-group-text add-on white">
														<i class="icon-calendar"></i>
													</span>
												</span>
											</div> -->
											
											<span>
												<select v-model="expense_type" class="form-control  ml-2">
													<option value="">所有费控类型</option>
													<option v-bind:key="index" 
														:value="item.value" v-for="(item,index) in expensetypelist">
														{{item.name}}</option>
												</select>
											</span>
											
											<div class="ml-2">
											
											<date-picker v-model="start_date" format="YYYY-MM-DD" value-type="format"
												input-class="form-control" :editable="false" :clearable="false">
											</date-picker>
											</div>

											<!-- <div class="input-group ml-2">
												<input v-model="end_date" id="date_timepicker_end" type="text" class="date-time-picker form-control" style="width: 105px;" >
												<span class="input-group-append">
													<span class="input-group-text add-on white">
														<i class="icon-calendar"></i>
													</span>
												</span>
											</div> -->

											<div class="ml-2">
												<date-picker v-model="end_date" format="YYYY-MM-DD" value-type="format"
													input-class="form-control" :editable="false" :clearable="false">
												</date-picker>
											</div>

											<button @click="search" type="button" class="btn btn-secondary  ml-2"><i
													class="icon-search"></i>
											</button>
										</div>
										<!-- 搜索 end -->
									</div>
									<!-- <div class="col-md-3">
										<div class="float-right m-3">
											<button type="button" class="btn btn-secondary">导出</button>
										</div>
									</div> -->
								</div>
							</div>

							<div class="table-responsive">
								<div v-if="billlist.length > 0">
									<table class="table table-striped table-hover r-0  mb-0">
										<thead>
											<tr class="no-b">
												<th style="width: 60px">
													<!-- <div class="custom-control custom-checkbox">
														<input v-model="check_all" @click.stop="checkAll" type="checkbox"  class="custom-control-input" id="checkedAll"><label class="custom-control-label" for="checkedAll"></label>
													</div> -->
													序号
												</th>
												<th>支付时间</th>
												<th>
													<div class="d-none d-lg-block">交易单号</div>
												</th>
												<th>
													<div class="d-none d-lg-block">费用类型</div>
												</th>
												<th>
													<div class="d-none d-lg-block">员工</div>
												</th>
												<th>
													<div class="d-none d-lg-block">账单分类</div>
												</th>
												<th>
													<div class="d-none d-lg-block">商户名称</div>
												</th>
												<th>
													<div class="d-none d-lg-block">订单总金额(元)</div>
												</th>
												<th>
													<div class="d-none d-lg-block">企业代付金额(元)</div>
												</th>
												<th v-show="outlet_level==0">
													<div class="d-none d-lg-block">状态</div>
												</th>
												
												<th></th>
											</tr>
										</thead>

										<tbody>
											<tr v-bind:key="index" v-for="(item,index) in billlist"
												:class="{'blue lighten-5':selectedIndex == index}">
												<td>
													{{index+1}}
													<!-- <label class="custom-control custom-checkbox">
														<input v-model="checkedIds" :value="item.id" :id="index" :disabled="item.enabled == -1" class="custom-control-input" type="checkbox"><label class="custom-control-label" :for="index"></label>
													</label> -->
												</td>
												<td>
													<div class="d-none d-lg-block">{{item.gmt_biz_create}}</div>
												</td>

												<td>
													<div class="d-none d-lg-block text-ellipsis" style="width: 240px;">{{item.pay_no}}</div>
												</td>

												<td>
													<div class="d-none d-lg-block">
														{{expenseTypeMap[item.expense_type]}}
														>>
														{{subexpenseTypeMap[item.expense_type_sub_category]}}
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
													{{item.employee_name}}
													<br>
													<span class="s-12 text-muted">{{item.employee_id}}</span>
													</div>
												</td>

												<td>
													<div class="d-none d-lg-block">{{item.category_name}}</div>
												</td>
												
												<td>
													<div class="d-none d-lg-block">{{item.merchant_name}}</div>
												</td>
			
												<td>
													<div class="d-none d-lg-block">
														<!-- <span class="r-3  font-weight-bold">{{item.peer_pay_amount}}</span> -->
														<span class="r-3  font-weight-bold">{{item.consume_amount}}</span>
														
														<template v-if="item.consume_type=='REFUND'">
															<div><label class="badge badge-pill badge-danger text-white">退款</label></div>
														</template>
														<template v-if="item.consume_type=='CONSUME'">
														<span v-if="item.peer_refund_amount>0" class="d-lg-block text-warning">退款{{item.peer_refund_amount}}</span>
														</template>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">{{item.peer_pay_amount}}</div>
												</td>

												<td v-show="outlet_level==0">
													<div class="d-none d-lg-block">
														<!-- {{item.pay_status}} | {{item.trade_status}} -->
														<span
															:class="[ 's-12  mr-2',{'text-success' : item.order_complete_label == 1,  'text-dark':item.order_complete_label == 0}]">
															{{item.order_complete_label==1?'已完结':'未完结'}}
														</span>
													</div>
												</td>
												
												<td>
													<button @click.stop="view(index)" type="button"
														class="btn btn-outline-primary btn-xs"> 详情 </button>
													
												</td>
											</tr>


										</tbody>
										<tfoot>
											<tr class="no-b">
												<th></th>
												<th>小计</th>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th>
													<div class="d-none d-lg-block">{{subtotalAmount}}</div>
												</th>
												<th colspan="3"></th>
											</tr>
											<tr class="no-b">
												<th>
												</th>
												<th>合计</th>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th>
													<div class="d-none d-lg-block">{{totalAmount}}</div>
												</th>
												<th colspan="3"></th>
											</tr>
										</tfoot>
									</table>
								</div>
								<div v-if="billlist.length == 0">
									<div class="card-body text-lg-center m-5 height-300">
										<h5>没有账单明细</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row" v-if="billlist.length > 0">
					<div class="col-sm-12 col-md-5">
						<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
							显示 {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
						</div>
					</div>
					<div class="col-sm-12 col-md-7">

						<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>

					</div>
				</div>

			</div>

		</div>
		<!-- //main content -->

		<!-- modal -->
		<!-- 详情 modal -->
		<div class="modal fade" id="hModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">账单详情</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-0">
							<div class="invoice white shadow">
								<div class="p-2">
									
									<!-- {{consume_info}} -->
									<div class="text-center">
									<h3>
									  {{bill.merchant_name}}
									</h3>
									 <h2 class="text-warning">
									<!-- <template v-if="bill.consume_type=='CONSUME'">-</template> -->
									 {{bill.consume_amount}}</h2>
									 <div class="text-black-50 mb-5">企业代扣{{bill.peer_pay_amount}}</div>
									 <template v-if="bill.consume_type=='REFUND'">
									 	<div><label class="badge badge-pill badge-danger text-white">退款</label></div>
									 </template>
									 </div>
									
									<dl class="row">
									  <dt class="col-sm-3">当前状态</dt>
									  <dd class="col-sm-9">
										{{bill.order_complete_label==1?'已完结':'未完结'}}
									  </dd>
									
									  <dt class="col-sm-3">商户名称</dt>
									  <dd class="col-sm-9">
										{{bill.merchant_name}}
									  </dd>
									
									  <dt class="col-sm-3">支付时间</dt>
									  <dd class="col-sm-9">
										  {{bill.gmt_receive_pay}}
									  </dd>
									  
									  <dt class="col-sm-3">支付方式</dt>
									  <dd class="col-sm-9">
										{{categoryMap[bill.category]}}
									  </dd>
									
									  <dt class="col-sm-3 text-truncate">交易单号</dt>
									  <dd class="col-sm-9">
										  <div class="text-ellipsis" style="width: 240px;">{{bill.pay_no}}</div>
									  </dd>
									  
									  <dt class="col-sm-3 text-truncate">余额</dt>
									  <dd class="col-sm-9">
									  		{{bill.balance}}
									  </dd>
									</dl>
									
									

								</div>

							</div>
						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">关闭</button>
					</div>
				</div>
			</div>
		</div>
		<!-- //详情 modal -->

		


	</div>
</template>

<script>
	const __payment = {
		'CASH': '现金',
		'CARD': '刷卡',
		'COUPON': "券",
		'BALANCE': '会员卡',
		'WEIXIN': '微信支付',
		'ALIPAY': '支付宝',
		'COMP': '组合付款'
	}

	const __tradeStatus5 = {
		'-1': '已取消',
		'0': '待付款',
		'1': '待配送',
		'2': '已送出',
		'3': "已送达",
		'4': '',
		'5': '',
		'6': '已结单'
	}
	const __tradeStatus1 = {
		'-1': '已取消',
		'0': '待付款',
		'1': '已完成'
	}
	const __payStatus = {
		'-1': '已取消',
		'0': '未付款',
		'1': '已付款'
	}

	import VPagination from '../components/my-page.vue'
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';
	import 'vue2-datepicker/locale/zh-cn';
	
	export default {
		data() {
			return {
				title: '消费订单列表',

				token: '',
				outlet_id: 0,
				outlet_name: '',
				user_name: '',
				outlet_level: 0,

				setting: {},
				outletlist: [],

				query: '',
				group_id: 0,

				current_page: 1,
				page_size: 50,
				count: 0,
				total_page: 0,
				show_from: 0,
				show_to: 0,

				total: 0, // 记录总条数
				display: 10, // 每页显示条数
				current: 1, // 当前的页数

				selectedIndex: -1,
				bill: {}, //选中的对象
				billitemlist: [],

				//////
				start_date: '',
				end_date: '',
				payment: '',
				employee_id: 0,
				order_type: 0,
				pay_status: -9,
				trade_status: -9,

				employeelist: [],
				billlist: [],

				subtotalAmount: 0,
				totalAmount: 0,

				//以下扫码点餐
				carrierInfo: {
					name: '',
					phone_number: '',
				},
				addressInfo: {},
				disabled: false,
				
				////
				dept_id: '',
				subdepartmentlist: [],
				sub_dept_id: '',//企业下部门，搜索条件
				subdeptmap: {}, //key为 dept id, value为 dept
				
				expense_type: '',
				expenseTypeMap: {
					'DEFAULT': '通用',
					'MEAL':'餐饮',
					'MALL':'商城',
					'METRO': '公交地铁',
					'PREPAYRECHARGE': '话费充值',
					'HOTEL': '酒店',
					'TRAIN': '火车票',
					'PLANE': '机票',
					'CAR': '用车',
				},
				subexpenseTypeMap: {
					'DEFAULT': '通用',
					'REACH_SHOP':'到店',
					'TAKE_AWAY':'外卖',
					'ONLINE_MALL':'线上商城',
					'METRO': '公交地铁',
					'ONLINE_PREPAY_RECHARGE': '线上充值',
					'REACH_SHOP_HOTEL': '到店住宿',
					'ONLINE_HOTEL': '在线订酒店',
					'ONLINE_TRAIN': '火车票预订',
					'ONLINE_PLANE': '机票预订',
					'CAR': '用车',
				},
				expensetypelist: [
					{name: '餐饮', value: 'MEAL'},
					{name: '通用', value: 'DEFAULT'},
					{name: '公交地铁', value: 'METRO'},
					{name: '用车', value: 'CAR'},
					{name: '话费充值', value: 'PREPAYRECHARGE'},
					{name: '酒店', value: 'HOTEL'},
					{name: '火车票', value: 'TRAIN'},
					{name: '机票', value: 'PLANE'},
				],
				
				consume_info: {},
				
				// categoryMap: {
				// 	"DEFAULT": "弹性福利",
				// 	"MEAL": "餐食外卖",
				// 	"CAR": "交通出行",
				// 	"COMMON": "工会福利",
				// },
				
				categorylist: this.GLOBAL.categorylist,
				categoryMap: [],
			}

		},
		components: {
			VPagination,
			DatePicker
		},
		
		computed: {
			activeEmployees: function () {
				let self = this;
				return this.employeelist.filter(function (item) {
					return item.outlet_id == self.outlet_id;//返回本店的
				})
			}
		},

		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;

			//2.取得商户信息：B企业用户登录有
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			self.dept_id = lsLoginData.dept_id;
			
			//构造categoryMap
			self.categoryMap = [];
			for(let i=0; i<this.GLOBAL.categorylist.length; i++){
				let et = this.GLOBAL.categorylist[i];
				self.categoryMap[et.value] = et.name;
			}
		},

		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//先取预载数据，再取业务数据
			self.init(() => {
				self.initData();
			})
		},
		methods: {

			init(callback) {
				let self = this;
			
				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'getSubDepartmentList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,//企业ID主键
							// query: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.subdepartmentlist = data.data.departments;
							
							for (let i = 0; i < self.subdepartmentlist.length; i++) {
								let dept = self.subdepartmentlist[i];
								self.subdeptmap[dept.id] = dept;
							}
							console.log('--- dept map ->', self.subdeptmap);
							
							// self.institution.effective_start_date = data.data.current_date;
							
							if (typeof callback == 'function') {
							    callback()
							}
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							// location.href = "index.html";
							self.logoff();
						} 
						else {
							alert(data.message)
						}
					});
				//END
			
			},

			initData() {
				let self = this;
				
				self.loader = self.$loading.show();

				//取得员工列表
				self.axios.get(self.GLOBAL.baseURI + 'getBillList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
							sub_dept_id: self.sub_dept_id,
							expense_type: self.expense_type,
							query: self.query,
							start_date: self.start_date,
							end_date: self.end_date,
							current_page: self.current_page,
							page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						self.loader.hide();
						
						console.log(data.data);
						if (data.code == 200) {
							self.billlist = []; //重置

							self.billlist = data.data.bill_list;
							self.subtotalAmount = data.data.subtotalAmount;
							self.totalAmount = data.data.total_amount;
							self.total = data.data.totalCount;

							self.start_date = data.data.start_date;
							self.end_date = data.data.end_date;

							self.current_page = data.data.page_num;
							self.page_size = data.data.page_size;
							self.count = data.data.total;
							self.total_page = Math.ceil(self.count / self.page_size);
							console.log('--- self.total_page ->', self.total_page)

							self.show_from = self.page_size * (self.current_page - 1) + 1;
							self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
								.current_page : self.count;

							self.total = self.count; // 记录总条数
							self.display = self.page_size; // 每页显示条数
							self.current = self.current_page; // 当前的页数

							
							// for (let order of data.data.bill_list) {
							// 	order.paymentDesc = __payment[order.payment];
							// 	order.payStatusDesc = __payStatus[order.pay_status]
							// 	order.tradeStatusDesc = order.order_type == 5 ? __tradeStatus5[order
							// 		.trade_status] : __tradeStatus1[order.trade_status]
							// 	self.billlist.push(order);
							// }
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END

			},

			pagechange: function(currentPage) {
				console.log(currentPage);
				// ajax请求, 向后台发送 currentPage, 来获取对应的数据
				this.current_page = currentPage;
				this.initData();
			},

			search() {
				let self = this;

				self.initData();
			},

			view(index) {
				console.log(index);
				let self = this;

				let bill = self.billlist[index];
				console.log(index, bill);

				self.selectedIndex = index;
				self.bill = bill;

				//远程数据
				// self.getBillDetails(() => {
				// 	$('#hModal').modal('show');
				// });
				
				//本地数据
				$('#hModal').modal('show');
			},
			
			getBillDetails(callback) {
				let self = this;
				console.log(self.bill);
			
				if (!self.bill.id)
					return;
			
				// 组装数据
				let postData = {
					token: self.token,
					bill_id: self.bill.id,
					pay_no: self.bill.pay_no,
				}
			
				self.axios.get(self.GLOBAL.baseURI + 'getBillDetails', {
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
			
						if (data.code == 200) {
							self.consume_info = data.data.consume_info;
							if (typeof callback == 'function') {
								callback()
							}
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else {
							alert(data.message)
						}
					});
			},

			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.init(() => {
					self.initData();
					self.employee_id = 0;
				})

			},

		},
	}
</script>

<style>
	.mx-icon-calendar,
	.mx-icon-clear {
		right: 28px !important;
	}
	.text-ellipsis{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
